export const REFILL_AMOUNTS = [
  {
    id: 400,
    name: "400 RC",
  },
  {
    id: 1200,
    name: "1200 RC",
  },
  {
    id: 1500,
    name: "1500 RC",
  },
  {
    id: 2000,
    name: "2000 RC",
  },
  {
    id: 3000,
    name: "3000 RC",
  },
  {
    id: 3500,
    name: "3500 RC",
  },
  {
    id: 5000,
    name: "5000 RC",
  },
  {
    id: 10000,
    name: "10 000 RC",
  },
  {
    id: 15000,
    name: "15 000 RC",
  },
  {
    id: 18500,
    name: "18 500 RC",
  },
  {
    id: 20000,
    name: "20 000 RC",
  },
  // {
  //   id: 23990,
  //   name: "23 990 RC",
  // },
  // {
  //   id: 30000,
  //   name: "30 000 RC",
  // },
  {
    id: 50000,
    name: "50 000 RC",
  },
  {
    id: 80000,
    name: "80 000 RC",
  },
  // {
  //   id: 95000,
  //   name: "95 000 RC",
  // },
  // {
  //   id: 98500,
  //   name: "98 500 RC",
  // },
  {
    id: 100000,
    name: "100 000 RC",
  },
  {
    id: 150000,
    name: "150 000 RC",
  },
  // {
  //   id: 200000,
  //   name: "200 000 RC",
  // },
  // {
  //   id: 245000,
  //   name: "245 000 RC",
  // },
  // {
  //   id: 248500,
  //   name: "248 500 RC",
  // },
  // {
  //   id: 250000,
  //   name: "250 000 RC",
  // },
  // {
  //   id: 300000,
  //   name: "300 000 RC",
  // },
];

export const REFILL_AMOUNTS_KZT = [
  {
    id: 400,
    name: "400 RC",
  },
  {
    id: 1200,
    name: "1200 RC",
  },
  {
    id: 1500,
    name: "1500 RC",
  },
  {
    id: 2000,
    name: "2000 RC",
  },
  {
    id: 3000,
    name: "3000 RC",
  },
  {
    id: 3500,
    name: "3500 RC",
  },
  {
    id: 5000,
    name: "5000 RC",
  },
  {
    id: 10000,
    name: "10 000 RC",
  },
  {
    id: 15000,
    name: "15 000 RC",
  },
  {
    id: 18500,
    name: "18 500 RC",
  },
  {
    id: 20000,
    name: "20 000 RC",
  },
  // {
  //   id: 50000,
  //   name: "50 000 RC",
  // },
  // {
  //   id: 80000,
  //   name: "80 000 RC",
  // },
  // {
  //   id: 95000,
  //   name: "95 000 RC",
  // },
  // {
  //   id: 98500,
  //   name: "98 500 RC",
  // },
  // {
  //   id: 100000,
  //   name: "100 000 RC",
  // },
];
